<template>
  <div id="mian">
    <div class="header">
      <span
        @click="navClick('批量查询')"
        :class="crumbs == '批量查询' ? 'active' : 'left'"
        >批量查询</span
      >
      <span
        @click="navClick('订单查询')"
        :class="crumbs == '订单查询' ? 'active' : 'left'"
        >订单查询</span
      >
    </div>
    <!-- 批量订单查询 -->
    <div v-if="crumbs == '批量查询'">
      <!-- 上面表单框 -->
      <div class="con_from">
        <div class="lineP">
          <div class="item">
            <i>分账商户</i>
            <el-input
              v-model="queryBatch.keyword"
              maxlength="128"
              suffix-icon="el-icon-search"
              placeholder="发起方/收款方名称"
            ></el-input>
          </div>
          <div class="item">
            <i>所属商户</i>
            <el-select
              v-model="queryBatch.mchId"
              placeholder="全部"
            >
              <el-option label="全部" value></el-option>
              <el-option
                v-for="item in MerchantsDropList"
                :key="item.mchId"
                :label="item.mchName"
                :value="item.mchId"
              ></el-option>
            </el-select>
          </div>
          <div class="item">
            <i>通知状态</i>
            <el-select
              v-model="queryBatch.reachStatus"
              placeholder="全部"
            >
              <el-option label="全部" value></el-option>
              <el-option label="已到达" value="true"></el-option>
              <el-option label="未响应" value="false"></el-option>
            </el-select>
          </div>
        </div>
        <div class="lineP">
          <div class="item">
            <i>通知结果</i>
            <el-select
              v-model="queryBatch.notifyStatus"
              placeholder="全部"
            >
              <el-option label="全部" value></el-option>
              <el-option label="成功" value="SUCCESS"></el-option>
              <el-option label="失败" value="FAIL"></el-option>
            </el-select>
          </div>
          <div class="item itemTime">
            <i>通知时间</i>
            <el-date-picker
              v-model="queryBatch.startTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择时间"
            ></el-date-picker>
            <span style="padding-left: 10px; padding-right: 10px">-</span>
            <el-date-picker
              v-model="queryBatch.endTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择时间"
            ></el-date-picker>
          </div>
        </div>
        <div class="lineP">
          <div class="itembtn">
            <el-button class="searchBt" @click="getLists">查询</el-button>
            <el-button v-auth="'OPERATION:NOTICES:TRANSFERZZ/SYNC'" class="outputBt" @click="synchronizationAll"
              >批量通知</el-button
            >
          </div>
        </div>
      </div>
      <!-- 切换 -->
      <div class="header_switch"> 
          <div
              :class="{ right: true, active: tabActive == 1 ? true : false }"
              @click="setTabActive(1)"
            >
              批次转账
          </div>
          <div
              :class="{ right: true, active: tabActive == 0 ? true : false }"
              @click="setTabActive(0)"
            >
              单笔转账
          </div>
      </div>

      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>通知时间</p>
            </td>
            <td>
              <p>商户名称</p>
            </td>
            <td>
              <p>发起方名称</p>
              <p v-if="tabActive == 0">收款方名称</p>
            </td>
            <td>
              <p>{{tabActive == 0 ? "转账订单号" : "批次转账订单号"}}</p>
              <p>{{tabActive == 0 ? "转账流水号" : "批次转账流水号"}}</p>
            </td>            
            <td>
              <p>通知状态</p>
            </td>
            <td>
              <p>通知结果</p>
            </td>
            <td>
              <p>状态码</p>
            </td>
            <td>
              <p>返回信息</p>
            </td>
            <td>
              <p>操作</p>
            </td>
          </tr>
          <tr v-for="(item, index) in queryBatchList" :key="index">
            <td>
              <p>{{ item.notifyTime }}</p>
            </td>
            <td>
              <p>{{ item.mchName }}</p>
            </td>
            <td>
              <p>{{ item.sourceMchName }}</p>
              <p v-if="tabActive == 0">{{ item.targetMchName }}</p>
            </td>
            <td>
              <p>{{ item.mchOrderNo }}</p>
              <p>{{ item.plfOrderNo }}</p>
            </td>
            <td>
              <p v-if="item.reachStatus == true">已到达</p>
              <p v-if="item.reachStatus == false || item.reachStatus == null">
                未响应
              </p>
            </td>
            <td>
              <p v-if="item.notifyStatus == 'SUCCESS'">成功</p>
              <p v-if="item.notifyStatus == 'FAIL'">失败</p>
            </td>
            <td>
              <p>{{ item.resultCode }}</p>
            </td>
            <td>
              <p>{{ item.resultMessage }}</p>
            </td>
            <td style="width: 80px">
              <p>
                <a
                  v-auth="'OPERATION:NOTICES:TRANSFERZZ/DETAIL'"
                  href="javascript:;"
                  @click="detail(item)"
                  class="lianjie"
                  >详情</a
                >
              </p>
              <p>
                <a v-if="tabActive == 1"
                    v-auth="'OPERATION:NOTICES:TRANSFERZZ/MINUTIA'"
                    href="javascript:;"
                    @click="carryOverBtn(item)"
                    class="lianjie"
                    >明细</a
                > 
                <i
                  v-auth="'OPERATION:NOTICES:TRANSFERZZ/SYNC'"
                  class="lianjie"
                  @click="synchronization(item)"
                  >通知</i
                >
              </p>
            </td>
          </tr>
        </table>
      </div>
      <el-pagination
        ref="pagination"
        background
        @size-change="handleSizeChangeByQueryBatch"
        @current-change="handleCurrentChangeByQueryBatch"
        :current-page="queryBatch.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryBatch.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalNumByQueryBatch"
      ></el-pagination>
    </div>
    <!-- 按订单号查询 -->
    <div v-if="crumbs == '订单查询'">
      <div class="con_from">
        <div class="lineP">
          <div class="item itemTwo">
            <el-select
              v-model="queryOrder.orderNoType"
              placeholder
              style="width: 170px; margin-right: 20px"
            >
              <el-option value="mchOrderNo" label="转账订单号"></el-option>
              <el-option value="plfOrderNo" label="转账流水号"></el-option>
              <el-option value="mchBatchNo" label="批次转账订单号"></el-option>
              <el-option value="plfBatchNo" label="批次转账流水号"></el-option>             
            </el-select>
            <el-input
              v-model="queryOrder.orderNo"
              maxlength="128"
              style="width: calc(100% - 170px - 20px)"
              suffix-icon="el-icon-search"
              :placeholder="'搜索' + orderNoType[queryOrder.orderNoType]"
            ></el-input>
          </div>
          <div class="itembtn itemTwobtn">
            <el-button class="searchBt" @click="getListsByOrder">查询</el-button>
          </div>
        </div>
      </div>
      <div class="tab1" style="min-height: 700px">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>通知时间</p>
            </td>
            <td>
              <p>商户名称</p>
            </td>
            <td>
              <p>发起方名称</p>
              <p v-if="tabActive == 0">收款方名称</p>
            </td>
            <td>
              <p>{{tabActive == 0 ? "转账订单号" : "批次转账订单号"}}</p>
              <p>{{tabActive == 0 ? "转账流水号" : "批次转账流水号"}}</p>
            </td>
            <td>
              <p>通知状态</p>
            </td>
            <td>
              <p>通知结果</p>
            </td>
            <td>
              <p>状态码</p>
            </td>
            <td>
              <p>返回信息</p>
            </td>
            <td>
              <p>操作</p>
            </td>
          </tr>
          <tr v-for="(item, index) in queryOrderList" :key="index">
            <td>
              <p>{{ item.notifyTime }}</p>
            </td>
            <td>
              <p>{{ item.mchName }}</p>
            </td>
            <td>
              <p>{{ item.sourceMchName }}</p>
              <p v-if="tabActive == 0">{{ item.targetMchName }}</p>
            </td>
            <td>
              <p>{{ item.mchOrderNo }}</p>
              <p>{{ item.plfOrderNo }}</p>
            </td>
            <td>
              <p v-if="item.reachStatus == true">已到达</p>
              <p v-if="item.reachStatus == false || item.reachStatus == null">
                未响应
              </p>
            </td>
            <td>
              <p v-if="item.notifyStatus == 'SUCCESS'">成功</p>
              <p v-if="item.notifyStatus == 'FAIL'">失败</p>
            </td>
            <td>
              <p>{{ item.resultCode }}</p>
            </td>
            <td>
              <p>{{ item.resultMessage }}</p>
            </td>
            <td style="width: 80px">
              <p>
                <a
                  v-auth="'OPERATION:NOTICES:TRANSFERZZ/DETAIL'"
                  href="javascript:;"
                  @click="detail(item)"
                  class="lianjie"
                  >详情</a
                >                
              </p>
              <p>
                <a v-if="tabActive == 1"
                    v-auth="'OPERATION:NOTICES:TRANSFERZZ/MINUTIA'"
                    href="javascript:;"
                    @click="carryOverBtn(item)"
                    class="lianjie"
                    >明细</a
                >                 
                <i
                  v-auth="'OPERATION:NOTICES:TRANSFERZZ/SYNC'"
                  class="lianjie"
                  @click="synchronization(item)"
                  >通知</i
                >
              </p>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <!-- 明细 -->
    <el-dialog title="转账明细" :visible.sync="carryOverShow" width="980px">
      <div class="tab1" style="min-height:auto;padding: 0px 20px;box-sizing: border-box;">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>商户订单号</p>
              <p>转账流水号</p>
            </td>   
            <td>
              <p>转账接收方编号</p>
            </td>
            <td>
              <p>转账金额</p>
            </td>
            <td>
              <p>实际扣款金额</p>
            </td>
            <td>
              <p>转账手续费</p>
            </td> 
            <td>
              <p>转账备注</p>
            </td>                   
          </tr>
          <tr v-for="(v, i) in carryOverData.batchTransferDetailList" :key="i">
            <td>
              <p>{{ v.mch_order_no }}</p>
              <p>{{ v.trx_no }}</p>
            </td>
            <td>
              <p>{{ v.target_mch_no }}</p>
            </td>
            <td>
              <p>{{ (v.transfer_amount / 100) | formatMoney }}</p>
            </td>  
            <td>
              <p>{{ (v.transfer_debit_amount / 100) | formatMoney }}</p>
            </td> 
            <td>
              <p>{{ (v.transfer_fee / 100) | formatMoney }}</p>
            </td>
            <td>
              <p>{{ v.transfer_remark }}</p>
            </td>                                      
          </tr>
        </table>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button class="exitBt" @click="carryOverShow = false">取 消</el-button>
        <el-button class="addBt1" @click="carryOverShow = false">确 定</el-button> -->
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { formatDate } from "@/utils/common.js";
import {
  transferZZQueryBatch,
  transferZZQueryOrder,
  transferZZPost,
  transferZZQueryorderBatch,
  transferZZQueryOrderSearch,
  transferZZPostBatch,
} from "@/api/operation/inform.js";
import { merchantsDrop } from "@/api/common.js";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      value: "",
      value1: "",
      value2: "",
      crumbs: "批量查询",
      orderNoType: {
        mchOrderNo: "转账订单号",
        plfOrderNo: "转账流水号",
        mchBatchNo: "批次转账订单号",
        plfBatchNo: "批次转账流水号",        
      },
      queryBatch: {
        // 批量查询
        pageNo: 1,
        pageSize: 10,
        startTime: formatDate(+new Date(), "yyyy-MM-dd 00:00:00"),
        endTime: formatDate(+new Date(), "yyyy-MM-dd 23:59:59"),
        notifyStatus: "",
        reachStatus: "",
        keyword: "",
        mchId: "",
      },
      queryOrder: {
        //订单查询
        pageNo: 1,
        pageSize: 10,
        orderNoType: "mchOrderNo",
        orderNo: "",
      },
      totalNumByQueryBatch: 0,
      totalNumByQueryOrder: 0,
      queryBatchList: [],
      queryOrderList: [],
      MerchantsDropList: [], //所属商户

      tabActive: 0, // 0 单笔 1 批量
      carryOverShow:false, //明细展示
      carryOverData:[],
      tabActiveData:{
        tabActive:"",
      },

    };
  },
  computed: {
    ...mapState({
      transferZZDetail: "operation_transferZZ",
    }),
  },
  created() {
    if (this.transferZZDetail) {
      this.queryBatch = this.transferZZDetail.queryBatch;
      this.queryOrder = this.transferZZDetail.queryOrder;
      this.tabActiveData = this.transferZZDetail.tabActiveData;
    }
    if(this.tabActiveData.tabActive == 1){
      this.queryBatch.pageNo = 1;
    }
    this.$nextTick(() => {
      this.$refs.pagination.internalCurrentPage = this.queryBatch.pageNo;
    });
    this.getMerchantsDrop();
    this.getLists(false);
    this.$enter(this.$route.path, this.search);
  },
  methods: {
    ...mapMutations({
      etTransferZZ: "operation_setTransferZZ",
    }),

    //菜单切换
    navClick(data) {
      this.crumbs = data;
      this.tabData = [];
      if (this.crumbs == "批量查询") {
        this.getLists();
      } else {
        this.tabActive = 0;
        if (this.queryOrder.orderNo) {
          this.getListsByOrder()
        }
      }
    },

    //切换表格
    setTabActive(e) {
      this.tabActive = e;
      this.tabActiveData.tabActive = e;
      this.tabData = [];
      this.transferNum = "";
      if (this.crumbs == "批量查询") {
        this.getLists();
      } else {
        if (this.queryOrder.orderNo) {
          this.getListsByOrder()
        }
      }
    },

    // 商户下拉列表
    getMerchantsDrop() {
      merchantsDrop()
        .then((res) => {
          this.MerchantsDropList = res.resultData;
        })
        .catch();
    },

    getLists(reset = true) {
      if (reset) {
        this.queryBatch.pageNo = 1;
      }
      this.queryBatch.startTime == null ? "" : this.queryBatch.startTime;
      this.queryBatch.endTime == null ? "" : this.queryBatch.endTime;
      this.queryBatch.keyword = this.queryBatch.keyword.trim();
      if(this.tabActive == "0"){
        console.log(this.tabActive,"tab值")
        transferZZQueryBatch(this.queryBatch).then((res) => {
            this.queryBatchList = res.resultData.notices;
            if (this.queryBatchList.length == 0) {
              this.$message({
                message: "暂无数据",
                duration: 1500,
              });
            }
            this.totalNumByQueryBatch = res.resultData.totalNum;
        }).catch();
      }else{
        console.log(this.tabActive,"tab值")
        transferZZQueryorderBatch(this.queryBatch).then((res) => {
            this.queryBatchList = res.resultData.notices;
            if (this.queryBatchList.length == 0) {
              this.$message({
                message: "暂无数据",
                duration: 1500,
              });
            }
            this.totalNumByQueryBatch = res.resultData.totalNum;
        }).catch();
      }
    },

    getListsByOrder() {
      let params = {
        pageNo: this.queryOrder.pageNo,
        pageSize: this.queryOrder.pageSize,
        orderNoType: this.queryOrder.orderNoType,
        orderNo: this.queryOrder.orderNo.trim(), 
      };
      if (!params.orderNo) {
        this.$message.error(
          "请输入" + this.orderNoType[this.queryOrder.orderNoType]
        );
        return;
      }
      if(this.queryOrder.orderNoType == "mchOrderNo" || this.queryOrder.orderNoType == "plfOrderNo"){
        this.tabActive = 0;
        transferZZQueryOrder(params).then((res) => {
            this.queryOrderList = res.resultData;
            if (this.queryOrderList.length == 0) {
              this.$message({
                message: "暂无数据",
                duration: 1500,
              });
            }
            // this.totalNumByQueryOrder = res.resultData.totalNum
        }) .catch();
      }else{
        this.tabActive = 1;
        transferZZQueryOrderSearch(params).then((res) => {
            this.queryOrderList = res.resultData;
            if (this.queryOrderList.length == 0) {
              this.$message({
                message: "暂无数据",
                duration: 1500,
              });
            }
            // this.totalNumByQueryOrder = res.resultData.totalNum
        }) .catch();        
      }

    },

    handleSizeChangeByQueryBatch(size) {
      this.queryBatch.pageSize = size;
      this.getLists();
    },

    handleCurrentChangeByQueryBatch(page) {
      this.queryBatch.pageNo = page;
      this.getLists(false);
    },

    // handleSizeChangeByQueryOrder(size) {
    //   this.queryOrder.pageSize = size;
    //   this.getListsByOrder();
    // },

    // handleCurrentChangeByQueryOrder(page) {
    //   this.queryOrder.pageNo = page;
    //   this.getListsByOrder();
    // },

    detail(row) {
      this.$router.push({
        path: "/operation/refund_ZZxq",
        query: {
          transferId: row.transferId,
          tabActive:this.tabActive,          
        },
      });
    },

    // 明细
    carryOverBtn(value){
      this.carryOverShow = true;
      this.carryOverData = value;
      console.log(this.carryOverData , "carryOverData");
    },    

    // 通知
    synchronization(item) {
      let data = {
        transferIds: [],
      };
      data.transferIds.push(item.transferId);
      if(this.tabActive == "0"){
        transferZZPost(data).then((res) => {
            if (res.resultStatus) {
              this.$message.success("通知成功");
            } else {
              this.$message.error("通知失败");
            }
        }).catch();        
      }else{
        transferZZPostBatch(data).then((res) => {
            if (res.resultStatus) {
              this.$message.success("通知成功");
            } else {
              this.$message.error("通知失败");
            }
        }).catch();
      }
    },

    synchronizationAll() {
      let data = {
        transferIds: [],
      };
      this.queryBatchList.forEach((item) => {
        data.transferIds.push(item.transferId);
      });
      if (data.transferIds.length < 1) {
        this.$message.error("缺少通知数据");
        return;
      }
      if(this.tabActive == "0"){
        transferZZPost(data).then((res) => {
            if (res.resultStatus) {
              this.$message.success("通知成功");
            } else {
              this.$message.error("通知失败");
            }
        }).catch();
      }else{
        transferZZPostBatch(data).then((res) => {
            if (res.resultStatus) {
              this.$message.success("通知成功");
            } else {
              this.$message.error("通知失败");
            }
        }).catch();
      }
    },

  },
  beforeDestroy() {
    let data = {
      queryBatch: this.queryBatch,
      queryOrder: this.queryOrder,
      tabActiveData:this.tabActiveData,
    };
    this.etTransferZZ(data);
  },
};
</script>
<style scoped>

</style>
